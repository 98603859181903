import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {CreditCardIcon} from "@heroicons/react/24/outline";
import useGetDataList from "hooks/useGetDataList";
import BaseDialog from "components/dialog";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Input from "components/form/Input";
import handleChange from "features/handleChange";
import handleValidation from "features/validation/validation";
import Loading from "components/loading";
import toast, {Toaster} from "react-hot-toast";
import useAuth from "hooks/useAuth";
import Filter from "components/form/Filter";
import getData from "requestApi/getData";
import Empty from "components/empty";
import InputQty from "components/form/InputQty";
import Select from "components/form/Select";
import QRCode from "react-qr-code";

const DISCOUNT_TYPE = {
    VALUE: 'value',
    PERCENTAGE: 'percentage'
}

const PAYMENT_TYPE = {
    CASH: 'Cash',
    KHQR: 'KHQR Photo',
    BANK: 'Bank'
}

const PAYMENT_METHODS = {
    'Cash': 'សាច់ប្រាក់',
    'KHQR Photo': 'រូបថតKHQR',
    'Bakong (NBC)': 'ប្រព័ន្ធបាគង',
    'ABA Bank': 'ធនាគារABA',
    'Wing Bank': 'ធនាគារWing',
}

export default function Cashier() {
    const {auth} = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const shopId = localStorage.getItem('shopId');
    const [branchId, setBranchId] = useState(0);
    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CASH);

    const url = '/product';
    const [params, setParams] = useState(auth.role === 'admin'
        ? {business_id: shopId}
        : {shop_id: shopId});
    const [page, setPage] = useState(1);
    const [branches, setBranches] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, meta, isLoading, setProducts, setMeta, setIsLoading] = useGetDataList(url, null, null, params);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);

    const [isModalPayment, setIsModalPayment] = useState(false);
    const cancelButtonRef = useRef(null);

    const [itemsProcessing, setItemsProcessing] = useState([]);

    const clearSearchRef = useRef(null);

    const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [returnUsd, setReturnUsd] = useState(0);
    const [payment, setPayment] = useState({
        receive: '',
    });

    const [isValidate, setIsValidate] = useState({
        receive: false,
        return: false
    });

    const [isLoadingQR, setIsLoadingQR] = useState(false);
    const [qrCodeString, setQrCodeString] = useState("");
    const [md5, setMd5] = useState("");
    const [paymentMethod, setPaymentMethod] = useState({});

    const addToCart = (product) => {
        if (product) {
            let isExist = false;
            setItemsProcessing(itemsProcessing.map(item => {
                if (item.product_id === product.id) {
                    isExist = true;
                    return {...item, qty: ++item.qty, totalPrice: item.price * item.qty};
                } else {
                    return item;
                }
            }));

            if (!isExist) setItemsProcessing([...itemsProcessing, {
                product_id: product.id,
                barcode: product.barcode,
                name_kh: product.name_kh,
                img_url: product.img_url,
                price: product.price,
                discount: 0,
                discount_type: DISCOUNT_TYPE.PERCENTAGE,
                qty: 1,
                totalPrice: product.price
            }]);
        }
    }

    const removeFromCart = (id) => {
        setItemsProcessing(itemsProcessing.filter(item => item.product_id !== id));
    }

    const handleSearchItem = async e => {
        if (e.key === 'Enter') {
            setIsLoadingSearch(true);
            const value = e.target.value;

            // Find product by barcode
            const product = products.find(product => product.barcode === value);

            if (product) {
                addToCart(product);
            } else {
                // Fetch product by barcode
                try {
                    const u = auth.role === 'admin' ? '/admin/product' : '/product';
                    const res = await axiosPrivate.get(u, {
                        params: {
                            barcode: value,
                            ...params
                        }
                    });
                    if (res.data.status === 200) {
                        addToCart(res.data.data);
                    } else if (res.data.status === 404) {
                        toast.error('រកមិនឃើញទំនិញ');
                    } else {
                        toast.error('មានបញ្ហាក្នុងការស្វែងរកសូមព្យាយាមម្តងទៀត');
                    }
                } catch (error) {
                    console.error("Failed to fetch product:", error);
                }
            }

            clearSearchRef.current.value = '';
            setIsLoadingSearch(false);
        }
    }

    const handleCheckoutSubmit = e => {
        e.preventDefault();
        handleCheckout().then(r => r);
    }

    const handleCheckout = async () => {
        if (!handleValidation(
            ['receive'],
            payment,
            setIsValidate
        )) return;
        if (returnUsd < 0) {
            setIsValidate(prevState => ({
                ...prevState,
                return: true
            }));
        } else {
            setIsValidate(prevState => ({
                ...prevState,
                return: false
            }));
            try {
                setIsLoadingCheckout(true);
                const data = {
                    carts: itemsProcessing,
                    received_usd: payment.receive,
                    received_khr: 0
                }
                const u = auth.role === 'admin' ? '/admin/order/pre-checkout' : '/order/pre-checkout';
                const res = await axiosPrivate.post(u, data);

                // Check if return usd is correct
                // get float number 2 decimal
                if (parseFloat(res.data.data.return_usd).toFixed(2) === returnUsd.toFixed(2)) {
                    await handleOrder()
                } else {
                    toast.error('មានបញ្ហាក្នុងការទូទាត់សូមព្យាយាមម្តងទៀត');
                    setIsLoadingCheckout(false);
                }
            } catch (error) {
                toast.error('មានបញ្ហាក្នុងការទូទាត់សូមព្យាយាមម្តងទៀត');
                setIsLoadingCheckout(false);
            }
        }
    }
    const [paymentMethods, setPaymentMethods] = useState([]);
    const handleGetPaymentMethods = useCallback(async () => {
        try {
            const id = () => {
                if (auth.role === 'admin') {
                    const pId = branchId === 0 ? branches[0].id : branchId;
                    if (pId === 0) {
                        toast.error('សូមជ្រើសរើសសាខា');
                        return;
                    }
                    return pId;
                } else {
                    return shopId;
                }
            }
            const u = auth.role === 'admin' ? '/admin/payment-method?shop_id=' + id() : '/payment-method?shop_id=' + shopId;
            const res = await axiosPrivate.get(u);
            if (res.data.status === 200) {
                setPaymentMethods(res.data.data);
            }
        } catch (error) {
            console.error("Failed to fetch payment methods:", error);
        }
    }, [auth.role, branchId, branches, shopId, axiosPrivate]);

    const handleOrder = async () => {
        try {
            const id = () => {
                if (auth.role === 'admin') {
                    const pId = branchId === 0 ? branches[0].id : branchId;
                    if (pId === 0) {
                        toast.error('សូមជ្រើសរើសសាខា');
                        return;
                    }
                    return pId;
                } else {
                    return shopId;
                }
            }
            const data = {
                shop_id: Number(id()),
                received_usd: payment.receive,
                received_khr: 0,
                payment_type: paymentType,
                order_details: itemsProcessing
            }
            const u = auth.role === 'admin' ? '/admin/order' : '/order';
            const res = await axiosPrivate.post(u, data);

            if (res.data.status === 201) {
                toast.success('បានទូទាត់ជោគជ័យ');
            } else {
                toast.error('មានបញ្ហាក្នុងការទូទាត់សូមព្យាយាមម្តងទៀត');
            }
            setIsModalPayment(false);
            setItemsProcessing([]);
        } catch (error) {
            console.error("Failed to order:", error);
        }

        setIsLoadingCheckout(false);
    }

    const sumPrice = useCallback((data) => {
        return (data.length === 0 ? 0 : (data[0].price * data[0].qty) + sumPrice(data.slice(1)));
    }, []);

    const sumDiscount = useCallback((data) => {
        if (data.length === 0) {
            return 0;
        } else if (data[0].discount_type === DISCOUNT_TYPE.VALUE) {
            return data[0].discount + sumDiscount(data.slice(1));
        } else {
            return (data[0].price * data[0].qty * data[0].discount) + sumDiscount(data.slice(1));
        }
    }, []);

    const sumTotalPrice = useCallback(() => {
        return subtotal - discount;
    }, [subtotal, discount]);

    // const date = new Date();
    // const dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes();

    useEffect(() => {
        // Reset form data when modal is closed
        if (!isModalPayment) {
            // wait for the modal to close
            setTimeout(() => {
                setPayment({
                    receive: '',
                });
                setIsValidate({
                    receive: false,
                    return: false
                });
                setReturnUsd(0);
                setPaymentType(PAYMENT_TYPE.CASH);
                setPaymentMethod({});
                setQrCodeString('');
                setMd5('');
            }, 200);
        } else {
            handleGetPaymentMethods().then(r => r);
        }
    }, [isModalPayment, handleGetPaymentMethods]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const verifyPayment = async () => {
            try {
                const url = '/payment/verify';
                const u = auth.role === 'admin' ? '/admin' + url : url;
                const data = { md5: md5 }
                const res = await axiosPrivate.post(u, data, { signal: controller.signal });

                if (res.data.status === 200) {
                    const id = () => {
                        if (auth.role === 'admin') {
                            const pId = branchId === 0 ? branches[0].id : branchId;
                            if (pId === 0) {
                                toast.error('សូមជ្រើសរើសសាខា');
                                return;
                            }
                            return pId;
                        } else {
                            return shopId;
                        }
                    }

                    const data = {
                        shop_id: Number(id()),
                        received_usd: total,
                        received_khr: 0,
                        payment_type: paymentType,
                        order_details: itemsProcessing
                    }
                    const u = auth.role === 'admin' ? '/admin/order' : '/order';
                    const res = await axiosPrivate.post(u, data);

                    if (res.data.status === 201) {
                        toast.success('បានទូទាត់ជោគជ័យ');
                    } else {
                        toast.error('មានបញ្ហាក្នុងការទូទាត់សូមព្យាយាមម្តងទៀត');
                    }
                    setIsModalPayment(false);
                    setItemsProcessing([]);
                } else if (res.data.status === 1) {
                    return isMounted && verifyPayment();
                } else if (res.data.status === 2) {
                    toast.error('QR code បានផុតកំណត់');
                } else if (res.data.status === 14) {
                    return isMounted && verifyPayment();
                } else {
                    toast.error('មានបញ្ហាក្នុងការទូទាត់សូមព្យាយាមម្តងទៀត');
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Failed to verify payment:', error);
                }
            }
        };

        if (isModalPayment && paymentType === PAYMENT_TYPE.BANK && md5 !== '') {
            verifyPayment().then(r => r);
        } else {
            controller.abort();
            isMounted = false;
        }

        return () => {
            controller.abort();
            isMounted = false;
        };

        }, [isModalPayment, paymentType, auth.role, axiosPrivate, branchId, branches, itemsProcessing, md5, shopId, total]);

    useEffect(() => {
        setSubtotal(sumPrice(itemsProcessing));
        setDiscount(sumDiscount(itemsProcessing));
        setTotal(sumTotalPrice());
    }, [itemsProcessing, sumPrice, sumDiscount, sumTotalPrice]);

    useEffect(() => {
        const productContainer = document.getElementById('product-list');

        const handleScroll = async () => {
            const {scrollTop, clientHeight, scrollHeight} = productContainer;
            if (scrollTop + clientHeight > scrollHeight - 20 && page < meta.total / meta.size && !isLoadMore) {
                setIsLoadMore(true);
                const p = page + 1;
                setPage(p);
                try {
                    const u = auth.role === 'admin' ? '/admin' + url : url;
                    const res = await axiosPrivate.get(u, {
                        params: {
                            page: p,
                            ...params
                        }
                    });
                    setProducts(prevProducts => [...prevProducts, ...res.data.data]);
                    setIsLoadMore(false);
                } catch (error) {
                    console.error("Failed to fetch more data:", error);
                }
            }
        };

        productContainer && productContainer.addEventListener('scroll', handleScroll);

        return () => {
            productContainer && productContainer.removeEventListener('scroll', handleScroll);
        };
    }, [axiosPrivate, isLoadMore, meta, setProducts, url, params, page, auth.role]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        auth.role === 'admin' && getData(
            controller,
            isMounted,
            '/shop?is_all=true&business_id=' + shopId,
            0,
            setBranches,
            null,
            null,
            'admin'
        ).then(r => r).catch(e => e);

        getData(
            controller,
            isMounted,
            auth.role === 'admin'
                ? '/category?is_all=true&business_id=' + shopId
                : '/category?is_all=true&shop_id=' + shopId,
            0,
            setCategories,
            null,
            null,
            auth.role === 'sale' ? 'sale' : 'admin'
        ).then(r => r).catch(e => e);

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [shopId, auth.role]);

    return (
        <>
            <div className="relative flex flex-col h-full">
                <div className="h-full w-full absolute pt-14 pb-4">
                    <div className="h-full flex space-x-4">
                        <div
                            className="h-full w-3/6 relative border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="h-full relative flex flex-col">
                                <div className="flex items-center space-x-4 mx-6 h-16">
                                    <h5 className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                                        ទំនិញ
                                    </h5>
                                </div>
                                <div className="h-full w-full absolute pt-16">
                                    {isLoading
                                        ? <Loading/>
                                        : products.length === 0
                                            ? <Empty title="ទំនិញ"/>
                                            : <div id="product-list"
                                                   className="h-full grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 overflow-scroll px-4 pb-4"
                                            >
                                                {products.map(product => (
                                                    <div
                                                        className="flex flex-col items-center h-fit w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                        <img className="aspect-square h-32 m-4 rounded-md object-cover"
                                                             src={product.img_url || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                                                             alt={product.name_kh}/>
                                                        <div className="px-4 pb-4 w-full">
                                                            <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{product.name_kh}</h5>
                                                            <h5 className="text-sm tracking-tight text-gray-900 dark:text-gray-300">{product.barcode}</h5>

                                                            <div className="flex items-center justify-between">
                                                            <span
                                                                className="text-3xl font-bold text-main">${product.price}</span>
                                                            </div>
                                                            <button
                                                                onClick={() => addToCart(product)}
                                                                className="mt-2 button h-10 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 font-medium text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                            >
                                                                បន្ថែមទៅកន្ត្រក
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                                {isLoadMore && <div
                                                    className="w-full h-12 flex items-center justify-center sm:col-span-1 lg:col-span-2 xl:col-span-3"
                                                >
                                                    <Loading/>
                                                </div>}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className="h-full w-3/6 relative border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="h-full relative flex flex-col">
                                <div className="flex items-center space-x-4 mx-6 h-16">
                                    <h5 className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                                        កន្ត្រក
                                    </h5>
                                    {!isLoading && auth.role === 'admin' && branches.length > 1 && <Filter
                                        isHasAll={false}
                                        title="សាខា"
                                        id="filter-branch"
                                        onChange={async (e) => {
                                            const {value} = e.target;
                                            setBranchId(value);
                                        }}
                                        selectOptions={branches}
                                        className={isModalPayment
                                            ? "me-8"
                                            : "z-50 me-8"
                                        }
                                    />}
                                </div>
                                <div className="h-full w-full absolute pt-16 pb-40">
                                    <div className="h-full overflow-scroll px-4">
                                        <ul className="-my-3 divide-y divide-gray-200">
                                            {itemsProcessing.map((product) => (
                                                <li key={product.id} className="flex py-3 items-center space-x-2">
                                                    <div
                                                        className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md">
                                                        <img
                                                            src={product.img_url || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                                                            alt={product.name_kh}
                                                            className="h-full w-full object-cover object-center"
                                                        />
                                                    </div>

                                                    <div className="flex flex-1 flex-col space-y-1">
                                                        <div>
                                                            <div
                                                                className="flex justify-between text-base font-medium text-gray-900 items-center">
                                                                <h3>
                                                                    {product.name_kh}
                                                                </h3>
                                                                <div className="flex">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeFromCart(product.product_id)}
                                                                        className="font-medium text-red-500 hover:text-red-600"
                                                                    >
                                                                        ដកចេញ
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <p className="text-sm text-gray-500">{product.barcode}</p>
                                                        </div>
                                                        <div
                                                            className="text-base flex flex-1 items-center justify-between w-full space-x-2">
                                                            <p className="text-main w-2/12">${product.price}</p>
                                                            <div className="flex space-x-2 w-7/12 h-8">
                                                                <InputQty
                                                                    id="qty"
                                                                    onChange={e => setItemsProcessing(itemsProcessing.map(item => {
                                                                        if (item.product_id === product.product_id) {
                                                                            let qty = parseInt(e.target.value) || 0;
                                                                            return {
                                                                                ...item,
                                                                                qty: qty,
                                                                                totalPrice: item.price * qty - item.discount
                                                                            };
                                                                        } else return item;
                                                                    }))}
                                                                    value={product.qty}
                                                                />
                                                                <Input
                                                                    className="flex-1"
                                                                    id="discount"
                                                                    onChange={e => setItemsProcessing(itemsProcessing.map(item => {
                                                                        if (item.product_id === product.product_id) {
                                                                            let discount = e.target.value;
                                                                            // Regular expression to check if input is a valid decimal number
                                                                            const regex = /^\d*\.?\d*$/;
                                                                            if (regex.test(discount)) {
                                                                                if (!isNaN(discount) && discount.trim() !== '' && discount.slice(-1) !== '.') {
                                                                                    discount = parseFloat(discount) || 0;
                                                                                }
                                                                            } else {
                                                                                // If input is not a valid decimal number, revert to the previous discount value
                                                                                discount = product.discount;
                                                                            }
                                                                            return {
                                                                                ...item,
                                                                                discount: item.discount_type === DISCOUNT_TYPE.VALUE ? discount : discount / 100,
                                                                                totalPrice: item.discount_type === DISCOUNT_TYPE.VALUE
                                                                                    ? item.price * item.qty - discount
                                                                                    : item.price * item.qty - (item.price * item.qty * discount) / 100
                                                                            };
                                                                        } else return item;
                                                                    }))}
                                                                    value={product.discount_type === DISCOUNT_TYPE.VALUE ? product.discount : product.discount * 100}
                                                                    leading="បញ្ចុះតម្លៃ:"
                                                                    leadingWidth="pl-16"
                                                                    textEnd={true}
                                                                    selectId="payment-type"
                                                                    selectOptions={[
                                                                        {value: DISCOUNT_TYPE.PERCENTAGE, label: "%"},
                                                                        {value: DISCOUNT_TYPE.VALUE, label: "$"}
                                                                    ]}
                                                                    selectWidth="pr-12"
                                                                    onSelected={(e) => {
                                                                        setItemsProcessing(itemsProcessing.map(item => {
                                                                            if (item.product_id === product.product_id) {
                                                                                return {
                                                                                    ...item,
                                                                                    discount_type: e.target.value,
                                                                                    discount: 0,
                                                                                    totalPrice: item.price * item.qty
                                                                                };
                                                                            } else return item;
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="font-bold text-lg text-main w-3/12 text-end">${product.totalPrice.toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 w-full px-4 pb-4">
                                    <div className="border-t border-gray-200">

                                    </div>
                                    <div
                                        className="mt-4 flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                        <p>សរុប</p>
                                        <p>${subtotal.toFixed(2)}</p>
                                    </div>
                                    <div
                                        className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                        <p>បញ្ចុះតម្លៃ</p>
                                        <p className="text-red-600">-${discount.toFixed(2)}</p>
                                    </div>
                                    <div
                                        className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                        <p>សរុបចុងក្រោយ</p>
                                        <p>${total.toFixed(2)}</p>
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            disabled={itemsProcessing.length === 0}
                                            className={itemsProcessing.length === 0 ? "button-disabled w-full" : "button w-full"}
                                            onClick={() => setIsModalPayment(true)}
                                        >
                                            រៀបចំការទូទាត់
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-10 mb-4 flex items-center justify-between">
                    <div className="relative flex items-center">
                        <h1 className="me-8">លក់ទំនិញ</h1>
                        {!isLoading && (
                            <>
                                <Filter
                                    title="ប្រភេទ"
                                    id="filter-category"
                                    onChange={async (e) => {
                                        const {value} = e.target;
                                        const p = value !== "all" ? {...params, category_id: value} : {...params};
                                        setParams(p);
                                        setIsLoading(true);
                                        const u = auth.role === 'sale' ? url : "/admin" + url;
                                        const res = await axiosPrivate.get(u, {
                                            params: {
                                                page: 1,
                                                ...p
                                            }
                                        });
                                        setProducts(res.data.data);
                                        setMeta(res.data.meta);
                                        setPage(1);
                                        setIsLoading(false);
                                    }}
                                    selectOptions={categories}
                                    isHasNon={true}
                                    className="w-52 me-8"
                                />
                            </>
                        )}
                    </div>
                    <label htmlFor="table-search" className="sr-only">ស្វែងរក</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </div>
                        <input onKeyDown={handleSearchItem}
                               ref={clearSearchRef}
                               type="text"
                               name="scan"
                               id="scan"
                               className="input w-80 pl-10"
                               placeholder="ស្វែងរកបារកូដ"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            {isLoadingSearch ? (
                                <svg aria-hidden="true"
                                     className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"/>
                                </svg>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <BaseDialog
                icon={<CreditCardIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>}
                title="ការបង់ប្រាក់"
                openModal={isModalPayment}
                setOpenModal={setIsModalPayment}
                cancelModalRef={cancelButtonRef}
                button={paymentType !== PAYMENT_TYPE.BANK &&
                    <button
                        disabled={isLoadingCheckout ? true : ""}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => handleCheckout()}
                    >
                        {isLoadingCheckout ? (
                            <>
                                <svg aria-hidden="true" role="status"
                                     className="inline w-4 h-4 mr-1 text-white animate-spin"
                                     viewBox="0 0 100 101"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor"/>
                                </svg>
                                កំពុងទូទាត់...
                            </>
                        ) : ("ទូទាត់")}
                    </button>}>
                <form
                    className="space-y-4"
                    onSubmit={handleCheckoutSubmit}
                >
                    <p className="text-center text-lg text-gray-500 dark:text-gray-200">
                        ទឹកប្រាក់ត្រូវបង់គឺ <span className="text-main font-bold">${total.toFixed(2)}</span>
                    </p>
                    <Select
                        title="ប្រភេទទូទាត់"
                        id="payment-type"
                        onChange={async e => {
                            if (e.target.value === PAYMENT_TYPE.CASH) {
                                setPaymentType(PAYMENT_TYPE.CASH);
                            } else if (e.target.value === PAYMENT_TYPE.KHQR) {
                                setPaymentType(PAYMENT_TYPE.KHQR);
                                setIsLoadingQR(true);

                                try {
                                    const url = '/payment-method/' + e.target.options[e.target.selectedIndex].dataset.key;
                                    const u = auth.role === 'admin' ? '/admin' + url : url;
                                    const res = await axiosPrivate.get(u);
                                    if (res.data.status === 200) {
                                        setPaymentMethod(res.data.data);
                                        setIsLoadingQR(false);
                                    }
                                } catch (error) {
                                    console.error('Error fetching data:', error);
                                }
                            } else {
                                setPaymentType(PAYMENT_TYPE.BANK);
                                setIsLoadingQR(true);

                                try {
                                    const url = '/payment-method/' + e.target.options[e.target.selectedIndex].dataset.key;
                                    const u = auth.role === 'admin' ? '/admin' + url : url;
                                    const resPayment = await axiosPrivate.get(u);
                                    if (resPayment.data.status === 200) {
                                        const data = {
                                            account_number: resPayment.data.data.account_number,
                                            account_name: resPayment.data.data.account_name,
                                            currency_type: 'usd',
                                            amount: total.toFixed(2)
                                        }
                                        const u = auth.role === 'admin' ? '/admin/payment/qr-code' : '/payment/qr-code';
                                        const resQrCode = await axiosPrivate.post(u, data);
                                        if (resQrCode.data.status === 200) {
                                            setQrCodeString(resQrCode.data.data.qr);
                                            setMd5(resQrCode.data.data.md5);
                                            setPaymentMethod(resPayment.data.data);
                                            setIsLoadingQR(false);
                                        }
                                    }
                                } catch (error) {
                                    console.error('Error fetching data:', error);
                                }
                            }
                        }}
                        value={paymentType}
                        selectOptions={
                            paymentMethods
                                .filter(method => method.status === 'Active')
                                .map(method => ({
                                    id: method.id,
                                    value: method.payment_method,
                                    name: PAYMENT_METHODS[method.payment_method]
                                }))
                        }
                    />
                    {paymentType !== PAYMENT_TYPE.BANK && <div>
                        <Input
                            title="ទឹកប្រាក់ទទួល"
                            id="receive"
                            onChange={e => {
                                handleChange(
                                    e,
                                    setPayment,
                                    setIsValidate
                                )
                                setReturnUsd(e.target.value - total);
                                setIsValidate(prevState => ({
                                    ...prevState,
                                    return: false
                                }));
                            }}
                            value={payment.receive}
                            selectId="currency"
                            selectOptions={[
                                {value: "USD", label: "USD"}
                            ]}
                            isValidate={isValidate.receive}
                            isRequire={true}
                        />
                        {isValidate.return &&
                            <div className="mt-2 text-sm text-red-600">ទឹកប្រាក់ទទួលមិនគ្រប់គ្រាន់</div>}
                    </div>}
                    {paymentType === PAYMENT_TYPE.KHQR && <div className="w-full mx-auto">
                        {isLoadingQR ? <Loading/> : <img
                            src={paymentMethod.qr_code_img_url}
                            alt="QR Code"
                            className="h-full rounded-lg"/>}
                    </div>}
                    {paymentType === PAYMENT_TYPE.BANK && <div className="text-center w-full space-y-2 dark:text-white">
                        {isLoadingQR ? <Loading/> : <>
                            <div className="p-2 dark:bg-white h-44 w-44 mx-auto">
                                <QRCode
                                    value={qrCodeString}
                                    style={{height: "auto", maxWidth: "100%", width: "100%"}}/>
                            </div>
                            <div>ឈ្មោះគណនី: {paymentMethod.account_name}</div>
                            <div>លេខគណនី: {paymentMethod.account_number}</div>
                        </>
                        }
                    </div>}
                    {paymentType !== PAYMENT_TYPE.BANK &&
                        <p className="mt-6 text-center text-lg text-gray-500 dark:text-gray-200">
                            ប្រាក់អាប់ <span className="text-main font-bold">${returnUsd.toFixed(2)}</span>
                        </p>}
                </form>
            </BaseDialog>
            <Toaster/>
        </>
    )
}